import React, { createContext, useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { Button, Container, Form, Select, Icon, Input, Menu, Segment, Table, Dropdown, Header, Label, Modal, TextArea, Checkbox, Divider, Pagination } from "semantic-ui-react";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import axios from "axios";
import _ from "lodash";
import { AuctionState, BANK_TYPE, Location, ReservationState, SaleTypeState, TransportState } from "../../consts/Consts";
import { toast } from "react-semantic-toasts";
import CarmonSearchCenter from "../popup/SearchCenter";
import CarmonImageViewer from "../popup/ImageViewer";
import CarmonReloadControl from "../control/ReloadControl";
import PerformanceCheckReport from "./performanceRecord/PerformanceCheckReport";
import moment from "moment";
import CarmonConfirm from "../common/Confirm";
import JSONPretty from "react-json-pretty";
import THEME_MONIKAI from "react-json-pretty/dist/monikai";
import NumberFormat from "react-number-format";
import CenterContact from "../popup/CenterContact";
import MessageSendHistoryPopup from "../popup/MessageSendHistoryPopup";

const COUNT_PER_PAGE = 10;
const DEFAULT_INSPECTION_AMOUNT = 35000;

const TIME_OPTIONS = [
  { key: "am9", value: 9, text: "9" },
  { key: "am9.5", value: 9.5, text: "9:30" },
  { key: "am10", value: 10, text: "10" },
  { key: "am1.50", value: 10.5, text: "10:30" },
  { key: "am11", value: 11, text: "11" },
  { key: "am1.51", value: 11.5, text: "11:30" },
  { key: "am12", value: 12, text: "12" },
  { key: "am1.52", value: 12.5, text: "12:30" },
  { key: "pm1", value: 13, text: "13" },
  { key: "pm1.5", value: 13.5, text: "13:30" },
  { key: "pm2", value: 14, text: "14" },
  { key: "pm2.5", value: 14.5, text: "14:30" },
  { key: "pm3", value: 15, text: "15" },
  { key: "pm3.5", value: 15.5, text: "15:30" },
  { key: "pm4", value: 16, text: "16" },
  { key: "pm4.5", value: 16.5, text: "16:30" },
  { key: "pm5", value: 17, text: "17" },
  { key: "pm5.5", value: 17.5, text: "17:30" },
  { key: "pm6", value: 18, text: "18" },
  { key: "pm6.5", value: 18.5, text: "18:30" },
  { key: "pm7", value: 19, text: "19" },
  { key: "pm7.5", value: 19.5, text: "19:30" },
];

const RESERVATION_STATE_OPTIONS = [
  { key: 0, value: " ", text: "전체" },
  { key: 1, value: ReservationState.WAIT, text: "대기" },
  { key: 2, value: ReservationState.ACCEPT, text: "확정" },
  { key: 3, value: ReservationState.AUCTION, text: "경매" },
  { key: 4, value: ReservationState.DONE, text: "종결" },
  { key: 5, value: ReservationState.CANCEL, text: "취소" },
];

const AUCTION_STATE_OPTIONS = [
  { key: "WA", value: AuctionState.WAIT, text: "대기" },
  { key: "AA", value: AuctionState.ACCEPT_AUCTION, text: "진행중" },
  { key: "SB", value: AuctionState.SUCCESS_BIDDING, text: "낙찰" },
  { key: "FB", value: AuctionState.FAILURE_BIDDING, text: "유찰" },
  { key: "SY", value: AuctionState.SALES_YES, text: "판매확정" },
  { key: "SN", value: AuctionState.SALES_NO, text: "판매거부" },
  { key: "RA", value: AuctionState.RETRY_AUCTION, text: "재경매" },
];

const SALE_TYPE_STATE_OPTIONS = [
  { key: 0, value: " ", text: "전체" },
  { key: 1, value: SaleTypeState.DEALER, text: "딜러" },
  { key: 2, value: SaleTypeState.PERSONAL, text: "개인" },
];

export const PerformanceModalOptionContext = createContext({ setPerformanceModalOption: () => {} });

const retrieveCommonCode = async (codeGroup) => {
  return await axios.get(`/apis/common/code_list/${codeGroup}`);
};

const retrieveCenter = async () => {
  return await axios.get("/apis/common/center");
};

const CarmonReservationList = () => {
  const locationState = useRef([]);
  const locationRegionAll = useRef([]);
  const bankList = useRef([]);
  const center = useRef([]);

  const [searchForm, setSearchForm] = useState({});
  const [locationRegion, setLocationRegion] = useState([]);

  const [pageable, setPageable] = useState({ page: 0, size: COUNT_PER_PAGE });

  const [jsonContentModal, setJsonContentModal] = useState({ open: false, content: "" });
  const [performanceModalOption, setPerformanceModalOption] = useState({ open: false, data: null, reservationId: 0 });

  const [selectedReservation, setSelectedReservation] = useState({ idx: 0, data: {} });
  const [centerDepositModalOption, setCenterDepositModalOption] = useState({
    open: false,
    close: () => {
      setCenterDepositModalOption((prevState) => {
        return {
          ...prevState,
          open: false,
        };
      });
    },
  });

  // const [searchFilter, setSearchFilter] = useState({
  //   hideCancel: true,
  //   hideComplete: true,
  // })

  const [modalProps, setModalProps] = useState({
    open: false,
    close: searchModalClose,
    reservation: null,
    callback: null,
  });

  const [imageModalProps, setImageModalProps] = useState({
    open: false,
    close: imageModalClose,
    reservation: null,
    callback: null,
  });

  const [confirmProps, setConfirmProps] = useState({
    type: "alert",
    title: "알림",
    content: "",
    open: false,
    close: () =>
      setConfirmProps((prevState) => {
        return { ...prevState, open: false };
      }),
    size: "large",
  });

  const [centerContactModalOption, setCenterContactModalOption] = useState({
    open: false,
    close: () => {
      setCenterContactModalOption((prevState) => ({ ...prevState, open: false }));
    },
    center: null,
  });

  const [messageSendHistoryModalOption, setMessageSendHistoryModalOption] = useState({
    open: false,
    close: () => {
      setMessageSendHistoryModalOption((prevState) => ({ ...prevState, open: false }));
    },
    keyword: null,
    centerId: null,
  });

  useEffect(async () => {
    const asyncInit = async () => {
      await init();
    };
    asyncInit();
  }, []);

  useEffect(() => {
    retrieveReservation();
  }, [pageable.page, pageable.size]);

  const init = useCallback(async () => {
    await initialCommonCode();
    await retrieveReservation();
  }, []);

  const initialCommonCode = async () => {
    const [locationStateResponse, locationRegionResponse, centerResponse, bankCodes, stockCodes] = await Promise.all([retrieveCommonCode(Location.STATE), retrieveCommonCode(Location.REGION), retrieveCenter(), retrieveCommonCode(BANK_TYPE.BANK), retrieveCommonCode(BANK_TYPE.STOCK)]);

    const _locationState = [];
    _locationState.push({ key: 0, value: " ", text: "전체" });
    locationStateResponse.data.map(({ commonCodeId: key, code: value, name: text }) => {
      _locationState.push({ key: key, value: value, text: text });
    });

    locationState.current = _locationState;
    locationRegionAll.current = locationRegionResponse.data;
    center.current = centerResponse.data;
    bankList.current = _.concat([], bankCodes.data, stockCodes.data);
  };

  const retrieveReservation = () => {
    let params = Object.assign({}, searchForm, pageable);
    delete params.centerName;

    axios
      .get("/apis/admin/reservation", { params: params })
      .then(({ data }) => {
        data.content.forEach((row) => {
          if (row["informationData"]) row["informationData"] = JSON.parse(row.informationData);
          if (row["carRegisterData"]) row["carRegisterData"] = JSON.parse(row.carRegisterData);
          if (row["accidentHistoryData"]) row["accidentHistoryData"] = JSON.parse(row.accidentHistoryData);
          if (row["checkRecordData"]) row["checkRecordData"] = JSON.parse(row.checkRecordData);
        });

        dispatch({ type: "CHANGE_DATA", data: data });
      })
      .catch((error) => {
        toast({
          type: "error",
          title: "ERROR",
          time: 2000,
          description: (error.response && error.response.data && error.response.data.message) || error,
        });
      });
  };

  const reloadCarRegister = (reservationId, callback) => {
    axios
      .post(`/apis/admin/external/car-register/${reservationId}`, {}, { timeout: 65000 })
      .then(() => {
        retrieveReservation();
      })
      .catch((error) => {
        toast({
          type: "error",
          title: "갱신 실패",
          time: 5000,
          description: (error.response && error.response.data && error.response.data.message) || error,
        });
      })
      .finally(() => {
        callback && callback();
      });
  };

  const reloadCarInformation = (reservationId, callback) => {
    axios
      .post(`/apis/admin/external/car-information/${reservationId}`, {}, { timeout: 65000 })
      .then(() => {
        retrieveReservation();
      })
      .catch((error) => {
        toast({
          type: "error",
          title: "갱신 실패",
          time: 5000,
          description: (error.response && error.response.data && error.response.data.message) || error,
        });
      })
      .finally(() => {
        callback && callback();
      });
  };

  const reloadAccidentHistory = (reservationId, callback) => {
    axios
      .post(`/apis/admin/external/accident/${reservationId}`, {}, { timeout: 65000 })
      .then(() => {
        retrieveReservation();
      })
      .catch((error) => {
        toast({
          type: "error",
          title: "갱신 실패",
          time: 5000,
          description: (error.response && error.response.data && error.response.data.message) || error,
        });
      })
      .finally(() => {
        callback && callback();
      });
  };

  const dataSortableReducer = (state, action) => {
    switch (action.type) {
      case "CHANGE_SORT":
        if (state.column === action.column) {
          return {
            ...state,
            data: state.data.slice().reverse(),
            direction: state.direction === "ascending" ? "descending" : "ascending",
          };
        }

        return {
          column: action.column,
          data: _.sortBy(state.data, [action.column]),
          direction: "ascending",
        };
      case "CHANGE_DATA":
        return {
          ...state,
          column: null,
          data: action.data,
          direction: null,
        };
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(dataSortableReducer, {
    column: null,
    data: {},
    direction: null,
  });

  const { column, data, direction } = state;

  const searchCenterCallback = (center, reservation) => {
    const reservationId = _.get(reservation, "data.reservationId");
    const currentReservation = _.find(data.content, { reservationId: reservationId });

    _.set(currentReservation, "centerId", center.centerId);
    _.set(currentReservation, "centerName", center.name);
    _.set(currentReservation, "centerContactNumber", center.contactNumber);

    dispatch({ type: "CHANGE_DATA", data: data });
  };

  const searchModalClose = () => {
    setModalProps((prevState) => {
      return {
        ...prevState,
        reservation: null,
        open: false,
      };
    });
  };

  const imageModalClose = () => {
    setImageModalProps((prevState) => {
      return {
        ...prevState,
        open: false,
      };
    });
  };

  const saveReservation = async (index, reservation, url) => {
    try {
      const response = await axios.put(url, reservation);

      if (response.status === 200) {
        const reservationId = _.get(reservation, "reservationId");
        const currentReservation = _.find(data.content, { reservationId: reservationId });

        _.merge(currentReservation, response.data);

        dispatch({ type: "CHANGE_DATA", data: data });

        toast({
          type: "success",
          title: "예약",
          time: 2000,
          description: "예약정보가 저장되었습니다.",
        });
      }
    } catch (error) {
      toast({
        type: "error",
        title: "저장 실패",
        time: 5000,
        description: (error.response && error.response.data && error.response.data.message) || error,
      });
    }
  };

  const eventHandler = {
    onClickClearSearchCenter: () => {
      setSearchForm((prevState) => {
        return {
          ...prevState,
          centerId: null,
          centerName: "",
        };
      });
    },
    onClickSearchCenterFromFilter: () => {
      setModalProps((prevState) => {
        return {
          ...prevState,
          withResTime: false,
          callback: (center) => {
            setSearchForm((prevState) => {
              return {
                ...prevState,
                centerId: center.centerId,
                centerName: center.name,
              };
            });
          },
          open: true,
          close: () => searchModalClose(),
        };
      });
    },
    onClickSearchCenter: (idx, selectedReservation) => {
      setModalProps((prevState) => {
        return {
          ...prevState,
          withResTime: true,
          reservation: {
            ...prevState.reservation,
            idx: idx,
            data: selectedReservation,
          },
          callback: (center, reservation) => searchCenterCallback(center, reservation),
          open: true,
          close: () => searchModalClose(),
        };
      });
    },
    onTimeSelect: (event, el, reservationId) => {
      const currentReservation = _.find(data.content, { reservationId: reservationId });
      _.set(currentReservation, "reservationTime", el.value);
      dispatch({ type: "CHANGE_DATA", data: data });
    },
    onDateSelect: (event, el, reservationId) => {
      const currentReservation = _.find(data.content, { reservationId: reservationId });
      _.set(currentReservation, "reservationDate", el.value);
      dispatch({ type: "CHANGE_DATA", data: data });
    },
    onChangeDescription: (event, el, reservationId) => {
      const currentReservation = _.find(data.content, { reservationId: reservationId });
      _.set(currentReservation, "note", el.value);
      dispatch({ type: "CHANGE_DATA", data: data });
    },
    onClickSaveDescription: (index, reservation) => {
      saveReservation(index, reservation, "/apis/admin/reservation/description");
    },
    onClickSave: (index, reservation) => {
      saveReservation(index, reservation, "/apis/admin/reservation");
    },
    onClickRestore: (index, reservation) => {
      axios
        .post(`/apis/admin/reservation/restore/${reservation.reservationId}`)
        .then((response) => {
          const reservationId = _.get(reservation, "reservationId");
          const currentReservation = _.find(data.content, { reservationId: reservationId });

          _.merge(currentReservation, response.data);

          dispatch({ type: "CHANGE_DATA", data: data });

          toast({
            type: "success",
            title: "예약",
            time: 2000,
            description: "예약이 복구되었습니다.",
          });
        })
        .catch((error) => {
          toast({
            type: "error",
            title: "복구 실패",
            time: 5000,
            description: (error.response && error.response.data && error.response.data.message) || error,
          });
        });
    },
    onClickDelete: (index, reservation) => {
      setConfirmProps((prevState) => {
        return {
          ...prevState,
          title: "확인",
          content: "삭제하시겠습니까?",
          open: true,
          type: "confirm",
          size: "tiny",
          callback: () => {
            axios
              .delete("/apis/admin/reservation", { data: reservation })
              .then((response) => {
                const reservationId = _.get(reservation, "reservationId");
                const currentReservation = _.find(data.content, { reservationId: reservationId });

                _.merge(currentReservation, response.data);

                dispatch({ type: "CHANGE_DATA", data: data });

                toast({
                  type: "success",
                  title: "예약",
                  time: 2000,
                  description: "예약이 취소되었습니다.",
                });
              })
              .catch((error) => {
                toast({
                  type: "error",
                  title: "삭제 실패",
                  time: 5000,
                  description: (error.response && error.response.data && error.response.data.message) || error,
                });
              });
          },
        };
      });
    },
    onChangeLocationState: (event, el) => {
      let filteredRegion = [{ key: 0, value: " ", text: "전체" }];
      locationRegionAll.current.map((o) => {
        if (o.masterCode === el.value) {
          filteredRegion.push({ key: o.commonCodeId, value: o.code, text: o.name });
        }
      });

      setLocationRegion(filteredRegion);
      eventHandler.onChangeSearchForm(event, el);
    },
    onChangeSearchForm: (event, el) => {
      setSearchForm((prevState) => {
        return {
          ...prevState,
          [el.name]: el.type === "checkbox" ? el.checked : el.value,
        };
      });
    },
    onClickImageViewer: (reservationNumber) => {
      setImageModalProps((prevState) => {
        return {
          ...prevState,
          open: true,
          close: imageModalClose,
          reservationNumber: reservationNumber,
        };
      });
    },
    onClickUploadImage: (reservationNumber) => {
      const imageUploadURL = `${process.env.IMAGE_UPLOAD_PAGE_URL}/${reservationNumber}`;
      window.open(imageUploadURL, "_blank");
    },
    onClickSavePerformanceCheckRecord: () => {
      savePerformanceCheckRecord();
    },
    onClickCenterDeposit: (index, reservation) => {
      setSelectedReservation({
        idx: index,
        data: {
          ...reservation,
          centerDepositBank: _.defaultTo(reservation.centerDepositBank, reservation.centerBank),
          centerDepositBankAccount: _.defaultTo(reservation.centerDepositBankAccount, reservation.centerBankAccount),
          centerDepositBankAccountName: _.defaultTo(reservation.centerDepositBankAccountName, reservation.centerBankAccountName),
          centerDepositAmount: _.defaultTo(reservation.centerDepositAmount, DEFAULT_INSPECTION_AMOUNT),
        },
      });

      setCenterDepositModalOption((prevState) => {
        return {
          ...prevState,
          open: true,
        };
      });
      //saveReservation(index, reservation, '/apis/admin/reservation/description');
    },
  };

  const savePerformanceCheckRecord = () => {
    axios
      .post(`/apis/admin/pcr/save/${performanceModalOption.reservationId}`, performanceModalOption.data)
      .then((response) => {
        const reservationId = performanceModalOption.reservationId;
        const currentReservation = _.find(data.content, { reservationId: reservationId });

        _.merge(currentReservation, response.data);

        dispatch({ type: "CHANGE_DATA", data: data });

        setPerformanceModalOption((prevState) => {
          return {
            ...prevState,
            open: false,
            data: null,
            reservationId: null,
            reservationIdx: null,
          };
        });

        toast({
          type: "success",
          title: "성능점검",
          time: 2000,
          description: "성능점검 데이터가 저장되었습니다.",
        });
      })
      .catch((error) => {
        toast({
          type: "error",
          title: "ERROR",
          time: 2000,
          description: (error.response && error.response.data && error.response.data.message) || error,
        });
      });
  };

  const performanceModal = (index, reservationId) => {
    const seriesName1 = _.get(data[index], "informationData.cardata.seriesname1");
    const seriesName2 = _.get(data[index], "informationData.cardata.seriesname2");

    axios
      .get(`/apis/admin/pcr/vehicle-information/${reservationId}`)
      .then(({ data }) => {
        setPerformanceModalOption((prevState) => {
          return {
            ...prevState,
            open: true,
            data: { ...data, BAS_010100: `${seriesName1} ${seriesName2}` },
            reservationId: reservationId,
            reservationIdx: index,
          };
        });
      })
      .catch((error) => {
        toast({
          type: "error",
          title: "ERROR",
          time: 2000,
          description: (error.response && error.response.data && error.response.data.message) || error,
        });
      });
  };

  const saveCenterDeposit = () => {
    axios
      .post(`/apis/admin/reservation/center/deposit`, selectedReservation.data)
      .then((response) => {
        const reservationId = _.get(selectedReservation, "data.reservationId");
        const currentReservation = _.find(data.content, { reservationId: reservationId });

        _.merge(currentReservation, response.data);

        dispatch({ type: "CHANGE_DATA", data: data });

        toast({
          type: "success",
          title: "예약",
          time: 2000,
          description: "입금등록되었습니다.",
        });
      })
      .catch((error) => {
        toast({
          type: "error",
          title: "오류",
          time: 5000,
          description: (error.response && error.response.data && error.response.data.message) || error,
        });
      });
  };

  const getTransportProcessing = (row) => {
    let stateText = "";

    if (row.transportState === TransportState.COMPLETE_TRANSPORT) {
      stateText = "거래종료";
    } else if (row.transportState === TransportState.START_TRANSPORT) {
      if (row.sellerDepositYn === "Y") stateText = "[판매자]대금지급완료";
      else stateText = "[탁송]탁송시작";
    } else if (row.transportState === TransportState.ASSIGN_MANAGER) {
      stateText = "[탁송]기사배정";
    } else if (row.dealerPaidYn === "Y") {
      stateText = "[딜러]입금완료";
    } else if (row.buyerInformationId > 0) {
      if (row.buyerInformationCheckTime === null) {
        stateText = "[딜러]매수자정보등록";
      } else {
        stateText = "[판매자]입금정보등록";
      }
    }

    return stateText;
  };

  const retrieveMessageSendHistory = (reservationNumber, centerId) => {
    setMessageSendHistoryModalOption((prevState) => {
      return {
        ...prevState,
        open: true,
        keyword: reservationNumber,
        centerId: centerId,
      };
    });
  };

  const renderPage = () => {
    const render = [];

    let row = 0;
    data.content?.map((o, idx) => {
      render.push(
        <Table.Row key={o.reservationId}>
          <Table.Cell collapsing>
            {o.reservationNumber}
            <br />
            <Button basic color={"orange"} size={"small"} onClick={() => retrieveMessageSendHistory(o.reservationNumber, o.centerId)}>
              알림톡 발송 내역
            </Button>
          </Table.Cell>
          <Table.Cell collapsing textAlign="center" positive={o.reservationState === ReservationState.ACCEPT} negative={o.reservationState === ReservationState.CANCEL}>
            <Header as="h5" color={o.reservationState === ReservationState.AUCTION ? "orange" : o.reservationState === ReservationState.ACCEPT ? "green" : o.reservationState === ReservationState.CANCEL ? "grey" : "black"}>
              {RESERVATION_STATE_OPTIONS.filter((s) => s.value === o.reservationState)[0]["text"]}
              {o.auctionState && <Header.Subheader>{AUCTION_STATE_OPTIONS.filter((v) => v.value === o.auctionState)[0].text}</Header.Subheader>}

              {o.transportState && <Header.Subheader>{getTransportProcessing(o)}</Header.Subheader>}
            </Header>
          </Table.Cell>

          <Table.Cell collapsing>
            <Header as="h5" style={{ margin: "0" }}>
              {o.licensePlateNumber}
            </Header>
            <Header as="h5" style={{ margin: "0" }} color="grey">
              {o.informationData && o.informationData.cardata && o.informationData.cardata.makername}
            </Header>
            <Header as="h5" style={{ margin: "0" }}>
              {o.informationData && o.informationData.cardata && o.informationData.cardata.modelname} ({o.informationData && o.informationData.cardata && o.informationData.cardata.year})
            </Header>
          </Table.Cell>

          <Table.Cell collapsing>
            <Header as="h5" style={{ margin: "0" }}>
              {o.name}
            </Header>
            <Header as="h5" style={{ marginTop: "0" }} color="grey">
              <NumberFormat displayType={"text"} format="###-####-####" value={o.contactNumber || ""} />
            </Header>
            {o.address}
          </Table.Cell>

          {/*<Table.Cell collapsing>*/}
          {/*  <Header as='h5' color='grey'>{getLocationStateName(o.locationState)}</Header>*/}
          {/*  {getLocationRegionName(o.locationRegion)}*/}
          {/*  {o.address}*/}
          {/*</Table.Cell>*/}

          {/*<Table.Cell collapsing>*/}
          {/*  <Input type='text'*/}
          {/*         disabled={o.reservationState !== ReservationState.WAIT}*/}
          {/*         value={o.name || ''}*/}
          {/*         onChange={(e, el) => eventHandler.onChangeSellerName(e, el, idx)}/>*/}
          {/*</Table.Cell>*/}
          <Table.Cell>
            <Input
              type="date"
              value={o.reservationDate || ""}
              disabled={o.reservationState !== ReservationState.WAIT}
              onChange={(e, el) => eventHandler.onDateSelect(e, el, o.reservationId)}
              min={moment().format("YYYY-MM-DD")}
              fluid
              style={{ marginBottom: 3, minWidth: "200px" }}
              action={<Dropdown button basic floating placeholder="시" scrolling upward={row > 5} options={TIME_OPTIONS} defaultValue={o.reservationTime} disabled={o.reservationState !== ReservationState.WAIT} style={{}} onChange={(e, el) => eventHandler.onTimeSelect(e, el, o.reservationId)} />}
              labelPosition="right"
            />
            <Input
              type="text"
              value={o.centerName || ""}
              readOnly={true}
              disabled={o.reservationState !== ReservationState.WAIT}
              fluid
              action={{
                icon: "search",
                basic: true,
                disabled: o.reservationState !== ReservationState.WAIT,
                onClick: () => eventHandler.onClickSearchCenter(idx, o),
              }}
            />
          </Table.Cell>

          <Table.Cell collapsing>
            <NumberFormat displayType={"text"} format="###-####-####" value={o.centerContactNumber || ""} />
            {o.centerId && (
              <>
                <br />
                <Button
                  basic
                  color={"blue"}
                  size={"tiny"}
                  icon={"list"}
                  content={"연락처보기"}
                  disabled={!(o.centerId && o.centerId > 0)}
                  onClick={() => {
                    if (o.centerId && o.centerId > 0) {
                      setCenterContactModalOption((prevState) => {
                        return {
                          ...prevState,
                          center: { centerId: o.centerId, name: o.centerName },
                          open: true,
                        };
                      });
                    }
                  }}
                />
              </>
            )}
          </Table.Cell>

          <Table.Cell collapsing>
            {_.isEmpty(_.defaultTo(o.centerDepositBank, o.centerBank)) ? (
              <Label style={{ width: "100%" }} basic color={"grey"}>
                계좌정보없음
              </Label>
            ) : (
              <Label style={{ width: "100%" }} basic color={"violet"}>
                {_.defaultTo(o.centerDepositBank && _.get(_.findLast(bankList.current, { code: o.centerDepositBank }), "name"), o.centerBankName)}
                <Label.Detail>{_.defaultTo(o.centerDepositBankAccount, o.centerBankAccount)}</Label.Detail>
                <Divider style={{ margin: "5px 0" }} />
                <Label.Detail style={{ float: "left", margin: 0 }}>{_.defaultTo(o.centerDepositBankAccountName, o.centerBankAccountName)}</Label.Detail>
              </Label>
            )}

            <Button
              size={"mini"}
              content={o.centerDepositId ? Intl.NumberFormat("ko-KR").format(o.centerDepositAmount) : "입금등록"}
              color={"blue"}
              basic={!!o.centerDepositId}
              icon={o.centerDepositId ? "check" : "krw"}
              style={{ display: "block", margin: "5px 0 0 0" }}
              disabled={o.reservationState === ReservationState.WAIT || o.reservationState === ReservationState.CANCEL}
              onClick={() => eventHandler.onClickCenterDeposit(idx, o)}
            />
          </Table.Cell>

          <Table.Cell>
            <Label color={o.saleType === "D" ? "orange" : "purple"}>{o.saleType === "D" ? "딜러" : "개인"}</Label>
          </Table.Cell>

          <Table.Cell collapsing textAlign="center">
            <Icon name={o.latestImageGroupId ? "images outline" : "times"} color={o.latestImageGroupId ? "blue" : "red"} style={{ cursor: "pointer" }} onClick={() => (o.latestImageGroupId ? eventHandler.onClickImageViewer(o.reservationNumber) : eventHandler.onClickUploadImage(o.reservationNumber))} />
          </Table.Cell>
          <Table.Cell collapsing textAlign="center">
            <Icon
              name={o.latestPcrId ? "file alternate outline" : "edit"}
              color={o.latestPcrId ? "green" : "red"}
              onClick={() => {
                performanceModal(idx, o.reservationId);
              }}
              style={{ cursor: "pointer" }}
            />
          </Table.Cell>

          <Table.Cell collapsing textAlign="center">
            {o.informationId ? (
              <Icon
                name="check"
                color="blue"
                onClick={() => {
                  setJsonContentModal({
                    open: true,
                    content: o.informationData,
                  });
                }}
                style={{ cursor: "pointer" }}
              />
            ) : (
              <CarmonReloadControl
                callback={(cb) => {
                  reloadCarInformation(o.reservationId, cb);
                }}
              />
            )}
          </Table.Cell>

          <Table.Cell collapsing textAlign="center">
            {o.accidentHistoryId ? (
              <Icon
                name="check"
                color="blue"
                onClick={() => {
                  setJsonContentModal({
                    open: true,
                    content: o.accidentHistoryData,
                  });
                }}
                style={{ cursor: "pointer" }}
              />
            ) : (
              <CarmonReloadControl
                callback={(cb) => {
                  reloadAccidentHistory(o.reservationId, cb);
                }}
              />
            )}
          </Table.Cell>

          <Table.Cell collapsing textAlign="center">
            {o.carRegisterId ? (
              <Icon
                name="check"
                color="blue"
                onClick={() => {
                  setJsonContentModal({
                    open: true,
                    content: o.carRegisterData,
                  });
                }}
                style={{ cursor: "pointer" }}
              />
            ) : (
              <CarmonReloadControl
                callback={(cb) => {
                  reloadCarRegister(o.reservationId, cb);
                }}
              />
            )}
          </Table.Cell>

          <Table.Cell>
            <Form>
              <TextArea value={o.note || ""} rows={2} onChange={(e, el) => eventHandler.onChangeDescription(e, el, o.reservationId)} style={{ width: "100%" }} />
              <Button fluid basic size={"mini"} content={"비고 저장"} color={"grey"} icon={"save"} onClick={() => eventHandler.onClickSaveDescription(idx, o)} />
            </Form>
          </Table.Cell>

          <Table.Cell collapsing>
            <Button basic color="teal" icon="save" onClick={() => eventHandler.onClickSave(idx, o)} />
            <Button
              basic
              color={o.reservationState === ReservationState.CANCEL ? "blue" : "red"}
              icon={o.reservationState === ReservationState.CANCEL ? "history" : "trash"}
              // disabled={o.reservationState !== ReservationState.WAIT && o.reservationState !== ReservationState.CANCEL}
              disabled={o.reservationState === ReservationState.AUCTION || o.reservationState === ReservationState.DONE}
              onClick={() => {
                if (o.reservationState === ReservationState.CANCEL) eventHandler.onClickRestore(idx, o);
                else eventHandler.onClickDelete(idx, o);
              }}
            />
          </Table.Cell>
        </Table.Row>,
      );
    });

    return render;
  };

  const value = useMemo(() => ({ setPerformanceModalOption }), [setPerformanceModalOption]);

  return (
    <PerformanceModalOptionContext.Provider value={value}>
      <Container fluid>
        <Segment attached="top">
          <Form>
            <Form.Group widths="3">
              <Form.Field>
                <label>접수일자</label>
                <Form.Group widths="equal">
                  <Form.Field>
                    <Input size="small" type="date" name="regFromDate" onChange={eventHandler.onChangeSearchForm} />
                  </Form.Field>
                  ~
                  <Form.Field>
                    <Input size="small" type="date" name="regToDate" onChange={eventHandler.onChangeSearchForm} />
                  </Form.Field>
                </Form.Group>
              </Form.Field>

              <Form.Field>
                <label>방문희망일자</label>
                <Form.Group widths="equal">
                  <Form.Field>
                    <Input size="small" type="date" name="reservationFromDate" onChange={eventHandler.onChangeSearchForm} />
                  </Form.Field>
                  ~
                  <Form.Field>
                    <Input size="small" type="date" name="reservationToDate" onChange={eventHandler.onChangeSearchForm} />
                  </Form.Field>
                </Form.Group>
              </Form.Field>

              <Form.Field>
                <label>지역</label>
                <Form.Group widths="equal">
                  <Form.Select options={locationState.current} placeholder="광역시/도" name="locationState" onChange={(event, el) => eventHandler.onChangeLocationState(event, el)} />

                  <Form.Select options={locationRegion} placeholder="시/구/군" name="locationRegion" onChange={eventHandler.onChangeSearchForm} />
                </Form.Group>
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <label>예약 정비소</label>
                <Input type="text" placeholder="정비소" readOnly={true} value={searchForm.centerName || ""} action>
                  <input />
                  <Button basic icon="search" onClick={() => eventHandler.onClickSearchCenterFromFilter()} />
                  <Button basic icon="times" onClick={() => eventHandler.onClickClearSearchCenter()} />
                </Input>
              </Form.Field>

              <Form.Field control={Input} name="licensePlateNumber" label="차량번호" placeholder="차량번호" onChange={eventHandler.onChangeSearchForm} />

              <Form.Field control={Input} name="sellerContactNumber" label="판매자 연락처" placeholder="판매자 연락처" onChange={eventHandler.onChangeSearchForm} />

              <Form.Field name="reservationState" control={Select} options={RESERVATION_STATE_OPTIONS} label="진행상태" placeholder="진행상태" onChange={eventHandler.onChangeSearchForm} />
            </Form.Group>

            <Form.Group widths="1">
              <Form.Field className="" control={Select} name="saleType" label="거래대상" placeholder="거래대상" options={SALE_TYPE_STATE_OPTIONS} onChange={eventHandler.onChangeSearchForm} />
              <Form.Field>
                <label>기타 옵션</label>
                <Checkbox label="취소건 제외" name={"withoutCancel"} onChange={eventHandler.onChangeSearchForm} />

                <Checkbox label="종결건 제외" name={"withoutDone"} style={{ marginLeft: "10px" }} onChange={eventHandler.onChangeSearchForm} />
              </Form.Field>
            </Form.Group>
          </Form>
        </Segment>
        <Segment attached="bottom" style={{ background: `#f9fafb` }}>
          <Button.Group floated={"right"}>
            <Button color="teal" onClick={retrieveReservation}>
              <Icon name="search" />
              Search
            </Button>
          </Button.Group>
          <div style={{ clear: "both" }} />
        </Segment>
      </Container>

      <Segment basic style={{ overflowX: "auto", padding: 0 }}>
        <Table striped sortable celled selectable size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
              // sorted={column === 'reservationNumber' ? direction : null}
              // onClick={() => dispatch({type: 'CHANGE_SORT', column: 'reservationNumber'})}
              >
                예약번호
              </Table.HeaderCell>
              <Table.HeaderCell
              // sorted={column === 'reservationState' ? direction : null}
              // onClick={() => dispatch({type: 'CHANGE_SORT', column: 'reservationState'})}
              >
                진행상태
              </Table.HeaderCell>

              <Table.HeaderCell
              // sorted={column === 'carInformation' ? direction : null}
              // onClick={() => dispatch({type: 'CHANGE_SORT', column: 'carInformation'})}
              >
                차량정보
              </Table.HeaderCell>

              <Table.HeaderCell
              // sorted={column === 'licensePlateNumber' ? direction : null}
              // onClick={() => dispatch({type: 'CHANGE_SORT', column: 'licensePlateNumber'})}
              >
                판매자정보
              </Table.HeaderCell>

              {/*<Table.HeaderCell*/}
              {/*  sorted={column === 'locationState' ? direction : null}*/}
              {/*  onClick={() => dispatch({type: 'CHANGE_SORT', column: 'locationState'})}*/}
              {/*>*/}
              {/*  주소*/}
              {/*</Table.HeaderCell>*/}

              {/*<Table.HeaderCell>*/}
              {/*  판매자이름&nbsp;*/}
              {/*  <Label size='mini' circular color='blue' empty/>*/}
              {/*</Table.HeaderCell>*/}
              <Table.HeaderCell
                width={3}
                // sorted={column === 'reservationDate' ? direction : null}
                // onClick={() => dispatch({type: 'CHANGE_SORT', column: 'reservationDate'})}
              >
                예약정보&nbsp;
                <Label size="mini" circular color="blue" empty />
              </Table.HeaderCell>

              <Table.HeaderCell>정비소연락처</Table.HeaderCell>

              <Table.HeaderCell>점검료지급</Table.HeaderCell>
              <Table.HeaderCell>거래대상</Table.HeaderCell>
              <Table.HeaderCell>사진</Table.HeaderCell>
              <Table.HeaderCell>점검</Table.HeaderCell>

              <Table.HeaderCell>
                소유자
                <br />
                검증
              </Table.HeaderCell>
              <Table.HeaderCell>
                사고
                <br />
                이력
              </Table.HeaderCell>
              <Table.HeaderCell>
                등록
                <br />
                원부
              </Table.HeaderCell>

              <Table.HeaderCell>
                비고사항&nbsp;
                <Label size="mini" circular color="blue" empty />
              </Table.HeaderCell>

              <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>{renderPage()}</Table.Body>

          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="14">
                <Header floated="left">Total {data?.totalElements}건</Header>
                <Menu floated="right">
                  {data?.totalPages && (
                    <Pagination
                      defaultActivePage={1}
                      totalPages={data?.totalPages}
                      siblingRange={4}
                      boundaryRange={0}
                      ellipsisItem={null}
                      onPageChange={(event, { activePage }) => {
                        setPageable((prevState) => {
                          return {
                            ...prevState,
                            page: activePage - 1,
                          };
                        });
                      }}
                    />
                  )}
                </Menu>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Segment>

      <CarmonSearchCenter {...modalProps} />

      <CarmonImageViewer {...imageModalProps} />

      <CarmonConfirm {...confirmProps} />

      <Modal open={jsonContentModal.open} onClose={() => setJsonContentModal({ open: false })} closeIcon centered={false}>
        <Modal.Content style={{ padding: 0, overflowX: "auto", background: "#272822" }}>
          <JSONPretty data={jsonContentModal.content} theme={THEME_MONIKAI} style={{ margin: 10 }} />
        </Modal.Content>
      </Modal>

      <Modal
        size={"large"}
        open={performanceModalOption.open}
        onClose={() =>
          setPerformanceModalOption((prevState) => {
            return { ...prevState, open: false, data: {} };
          })
        }
      >
        <Modal.Content>
          <PerformanceCheckReport performanceCheckReportData={performanceModalOption.data} />
        </Modal.Content>

        <Modal.Actions>
          <Button color={"blue"} content={"저장"} icon={"save"} onClick={() => eventHandler.onClickSavePerformanceCheckRecord()} />
          <Button
            color={"red"}
            content={"닫기"}
            icon={"times"}
            onClick={() =>
              setPerformanceModalOption((prevState) => {
                return { ...prevState, open: false, data: {} };
              })
            }
          />
        </Modal.Actions>
      </Modal>

      <Modal open={centerDepositModalOption.open} close={centerDepositModalOption.close} size={"mini"}>
        <Modal.Header>점검료 지급 등록</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field
              label={"은행"}
              control={Select}
              search={true}
              name={"centerDepositBank"}
              value={selectedReservation.data.centerDepositBank}
              options={_.map(bankList.current, (o) => {
                return { value: o.code, text: o.name };
              })}
              onChange={(e, { name, value }) => {
                setSelectedReservation((prevState) => {
                  return {
                    ...prevState,
                    data: {
                      ...prevState["data"],
                      [name]: value,
                    },
                  };
                });
              }}
            />

            <Form.Field
              label={"계좌번호"}
              control={Input}
              name={"centerDepositBankAccount"}
              value={selectedReservation.data.centerDepositBankAccount}
              onChange={(e, { name, value }) => {
                setSelectedReservation((prevState) => {
                  return {
                    ...prevState,
                    data: {
                      ...prevState["data"],
                      [name]: value,
                    },
                  };
                });
              }}
            />

            <Form.Field
              label={"예금주"}
              control={Input}
              name={"centerDepositBankAccountName"}
              value={selectedReservation.data.centerDepositBankAccountName}
              onChange={(e, { name, value }) => {
                setSelectedReservation((prevState) => {
                  return {
                    ...prevState,
                    data: {
                      ...prevState["data"],
                      [name]: value,
                    },
                  };
                });
              }}
            />

            <Form.Field
              label={"금액"}
              control={Input}
              name={"centerDepositAmount"}
              value={selectedReservation.data.centerDepositAmount}
              onChange={(e, { name, value }) => {
                setSelectedReservation((prevState) => {
                  return {
                    ...prevState,
                    data: {
                      ...prevState["data"],
                      [name]: value,
                    },
                  };
                });
              }}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color={"red"} icon={"times"} content={"닫기"} onClick={centerDepositModalOption.close} />
          <Button color={"blue"} icon={"save"} content={selectedReservation.data.centerDepositId ? "입금내역수정" : "입금등록"} onClick={saveCenterDeposit} />
        </Modal.Actions>
      </Modal>

      <CenterContact {...centerContactModalOption} />
      <MessageSendHistoryPopup {...messageSendHistoryModalOption} />
    </PerformanceModalOptionContext.Provider>
  );
};

export default CarmonReservationList;
