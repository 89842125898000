export const ImageBaseURL = process.env.IMAGE_BASE_URL;
export const CARNAWA_BASE_URL = process.env.CARNAWA_BASE_URL;

export const ReservationCheckExists = {
  NEW: "N",
  EXISTS: "E",
};

export const Location = {
  STATE: "LSTATE",
  REGION: "LREGION",
};

export const UseYn = {
  YES: "Y",
  NO: "N",
  NONE: "",
};

export const YesOrNo = {
  YES: "Y",
  NO: "N",
};

export const Association = [
  { key: 0, value: 0, text: "전체" },
  { key: 1, value: 1, text: "협회1" },
  { key: 2, value: 2, text: "협회2" },
];

export const ReservationState = {
  WAIT: "W", //예약대기
  ACCEPT: "A", //예약확정
  AUCTION: "P", //경매대기
  DONE: "D", //거래종결
  CANCEL: "C", //예약취소
};

export const SaleTypeState = {
  DEALER: "D", //딜러
  PERSONAL: "P", //개인
};

export const AuctionState = {
  WAIT: "WA", //대기
  ACCEPT_AUCTION: "AA", //진행
  SUCCESS_BIDDING: "SB", //낙찰 (판매자 컨펌 진행 알림톡 발송)
  FAILURE_BIDDING: "FB", //유찰
  SALES_YES: "SY", //판매승인
  SALES_NO: "SN", //판매거부
  RETRY_AUCTION: "RA", //경매 재출품
};

export const TransportState = {
  WAIT: "WA", // 탁송정보 입력
  ASSIGN_MANAGER: "SM", // 탁송기사 배정 완료
  START_TRANSPORT: "ST", // 탁송시작 (판매자에게 입금처리)
  COMPLETE_TRANSPORT: "CT", // 탁송완료
  FAILURE_TRANSPORT: "FT", // 처리불가
};

export const DISTANCE_UNIT = {
  KILOMETER: "KM",
  METER: "M",
};

export const BANK_TYPE = {
  BANK: "BANK",
  STOCK: "STOCK",
};

export const KAKAO_AT_RESULT = [
  { code: 7000, message: "전달" },
  { code: 7101, message: "카카오 형식 오류" },
  { code: 7103, message: "Sender key (발신프로필키) 유효하지 않음" },
  { code: 7105, message: "Sender key (발신프로필키) 존재하지 않음" },
  { code: 7106, message: "삭제된 Sender key (발신프로필키)" },
  { code: 7107, message: "차단 상태 Sender key (발신프로필키)" },
  { code: 7108, message: "차단 상태 옐로우 아이디" },
  { code: 7109, message: "닫힌 상태 옐로우 아이디" },
  { code: 7110, message: "삭제된 옐로우 아이디" },
  { code: 7203, message: "친구톡 전송 시 친구 대상 아님" },
  { code: 7204, message: "템플릿 불일치" },
  { code: 7300, message: "기타 에러" },
  { code: 7305, message: "성공 불확실(30일 이내 수신 가능)" },
  { code: 7306, message: "카카오 시스템 오류" },
  { code: 7308, message: "전화번호 오류" },
  { code: 7311, message: "메시지가 존재하지 않음" },
  { code: 7314, message: "메시지 길이 초과" },
  { code: 7315, message: "템플릿 없음" },
  { code: 7318, message: "메시지를 전송할 수 없음" },
  { code: 7322, message: "메시지 발송 불가 시간" },
  { code: 7323, message: "메시지 그룹 정보를 찾을 수 없음" },
  { code: 7324, message: "재전송 메시지 존재하지 않음" },
  { code: 7421, message: "타임아웃" },
];

export const PERFORMANCE_CHECK_RECORD = {
  MET_000001: { label: "전송구분", desc: "N = 신규,  U = 변경,  D = 삭제" },
  MET_000002: { label: "전송일자", desc: "예) YYYYMMDD" },
  MET_000003: { label: "전송시각", desc: "예) HHMMDD" },
  BAS_000002: { label: "성능지관리번호", desc: "협회에서 채번하는 성능지관리번호 PK" },
  BAS_000001: { label: "성능점검장코드", desc: "[K01] - 협회관리 성능점검장코드" },
  BAS_000003: { label: "성능점검일", desc: "[20210905]" },
  BAS_000004: { label: "성능점검장명", desc: "[(사)한국자동차기술인협회/울산진장]" },
  BAS_000005: { label: "성능점검자", desc: "[홍반장]" },
  BAS_000006: { label: "고객명", desc: "[홍길동]" },
  BAS_010000: { label: "차명", desc: "[소나타]" },
  BAS_010100: { label: "세부모델", desc: "" },
  BAS_020000: { label: "자동차등록번호", desc: "[121가1234]" },
  BAS_030000: { label: "연식", desc: "[1996]" },
  BAS_040100: { label: "검사유효기간 시작일", desc: "[20170905]" },
  BAS_040200: { label: "검사유효기간 종료일", desc: "[20180904]" },
  BAS_050000: { label: "최초등록일", desc: "[19960807]" },
  BAS_060000: { label: "차대번호", desc: "[KMZKL17DPTU123456]" },
  BAS_070000: { label: "변속기종류", desc: "[1:자동,2:수동,3:세미오토,4:무단변속기,5:기타]" },
  BAS_070100: { label: "변속기종류기타", desc: "" },
  BAS_080000: { label: "사용연료", desc: "[1:가솔린,2:디젤,3:LPG,4:하이브리드,5:기타,6:전기,7:수소전기]" },
  BAS_090000: { label: "원동기형식", desc: "[D4BA]" },
  BAS_990000: { label: "특기사항", desc: "" },
  TOT_110101: { label: "계기상태", desc: "[1:양호,2:불량]" },
  TOT_110201: { label: "주행거리상태", desc: "[1:많음,2:보통,3:적음]" },
  TOT_110202: { label: "주행거리항목", desc: "[163129]" },
  TOT_110301: { label: "차대번호표기", desc: "[1:양호,2:상이,3:부식,4:훼손,5:변조,6:도말]" },
  TOT_110401: { label: "일산화탄소상태", desc: "[2:checked]" },
  TOT_110402: { label: "일산화탄소항목", desc: "[0.04]" },
  TOT_110411: { label: "탄화수소상태", desc: "[2:checked]" },
  TOT_110412: { label: "탄화수소항목", desc: "[8.00]" },
  TOT_110421: { label: "매연상태", desc: "[2:checked]" },
  TOT_110422: { label: "매연항목", desc: "[50.00]" },
  TOT_110501: { label: "튜닝상태", desc: "[1:없음,2:있음]" },
  TOT_110511: { label: "튜닝", desc: "[1:적법,2:불법]" },
  TOT_110521: { label: "튜닝-구조", desc: "[2:checked]" },
  TOT_110522: { label: "튜닝-장치", desc: "[2:checked]" },
  TOT_110601: { label: "특별이력상태", desc: "[1:없음,2:있음]" },
  TOT_110611: { label: "특별이력-침수", desc: "[2:checked]" },
  TOT_110612: { label: "특별이력-화재", desc: "[2:checked]" },
  TOT_110701: { label: "용도변경상태", desc: "[1:없음,2:있음]" },
  TOT_110711: { label: "용도변경-렌트", desc: "[2:checked]" },
  TOT_110713: { label: "용도변경-영업용", desc: "[2:checked]" },
  TOT_110801: { label: "리콜대상", desc: "[1:해당없음,2:해당]" },
  TOT_110811: { label: "리콜이행", desc: "[1:이행,2:미이행]" },
  IMS_120001: { label: "후드", desc: "[X:교환,W:판금,C:부식,A:흠집,U:요철,T:손상]" },
  IMS_120004: { label: "트렁크리드", desc: "[X:교환,W:판금,C:부식,A:흠집,U:요철,T:손상]" },
  IMS_120005: { label: "라디에이터서포트", desc: "[X:교환,W:판금,C:부식,A:흠집,U:요철,T:손상]" },
  IMS_120007: { label: "루프패널", desc: "[X:교환,W:판금,C:부식,A:흠집,U:요철,T:손상]" },
  IMS_120009: { label: "프론트패널", desc: "[X:교환,W:판금,C:부식,A:흠집,U:요철,T:손상]" },
  IMS_120010: { label: "크로스멤버", desc: "[X:교환,W:판금,C:부식,A:흠집,U:요철,T:손상]" },
  IMS_120015: { label: "대쉬패널", desc: "[X:교환,W:판금,C:부식,A:흠집,U:요철,T:손상]" },
  IMS_120016: { label: "플로어패널", desc: "[X:교환,W:판금,C:부식,A:흠집,U:요철,T:손상]" },
  IMS_120017: { label: "트렁크플로어", desc: "[X:교환,W:판금,C:부식,A:흠집,U:요철,T:손상]" },
  IMS_120018: { label: "리어패널", desc: "[X:교환,W:판금,C:부식,A:흠집,U:요철,T:손상]" },
  IMS_120019: { label: "패키지트레이", desc: "[X:교환,W:판금,C:부식,A:흠집,U:요철,T:손상]" },
  IMS_121002: { label: "프론트휀더(좌)", desc: "[X:교환,W:판금,C:부식,A:흠집,U:요철,T:손상]" },
  IMS_121006: { label: "쿼터패털(좌)", desc: "[X:교환,W:판금,C:부식,A:흠집,U:요철,T:손상]" },
  IMS_121008: { label: "사이드실패널(좌)", desc: "[X:교환,W:판금,C:부식,A:흠집,U:요철,T:손상]" },
  IMS_121011: { label: "인사이드패널(좌)", desc: "[X:교환,W:판금,C:부식,A:흠집,U:요철,T:손상]" },
  IMS_121103: { label: "도어(좌전)", desc: "[X:교환,W:판금,C:부식,A:흠집,U:요철,T:손상]" },
  IMS_121112: { label: "사이드멤버(좌전)", desc: "[X:교환,W:판금,C:부식,A:흠집,U:요철,T:손상]" },
  IMS_121113: { label: "휠하우스(좌전)", desc: "[X:교환,W:판금,C:부식,A:흠집,U:요철,T:손상]" },
  IMS_121114: { label: "필러패널(좌전)", desc: "[X:교환,W:판금,C:부식,A:흠집,U:요철,T:손상]" },
  IMS_121214: { label: "필러패널(좌중)", desc: "[X:교환,W:판금,C:부식,A:흠집,U:요철,T:손상]" },
  IMS_121303: { label: "도어(좌후)", desc: "[X:교환,W:판금,C:부식,A:흠집,U:요철,T:손상]" },
  IMS_121312: { label: "사이드멤버(좌후)", desc: "[X:교환,W:판금,C:부식,A:흠집,U:요철,T:손상]" },
  IMS_121313: { label: "휠하우스(좌후)", desc: "[X:교환,W:판금,C:부식,A:흠집,U:요철,T:손상]" },
  IMS_121314: { label: "필러패널(좌후)", desc: "[X:교환,W:판금,C:부식,A:흠집,U:요철,T:손상]" },
  IMS_122002: { label: "프론트휀더(우)", desc: "[X:교환,W:판금,C:부식,A:흠집,U:요철,T:손상]" },
  IMS_122006: { label: "쿼터패털(우)", desc: "[X:교환,W:판금,C:부식,A:흠집,U:요철,T:손상]" },
  IMS_122008: { label: "사이드실패널(우)", desc: "[X:교환,W:판금,C:부식,A:흠집,U:요철,T:손상]" },
  IMS_122011: { label: "인사이드패널(우)", desc: "[X:교환,W:판금,C:부식,A:흠집,U:요철,T:손상]" },
  IMS_122103: { label: "도어(우전)", desc: "[X:교환,W:판금,C:부식,A:흠집,U:요철,T:손상]" },
  IMS_122112: { label: "사이드멤버(우전)", desc: "[X:교환,W:판금,C:부식,A:흠집,U:요철,T:손상]" },
  IMS_122113: { label: "휠하우스(우전)", desc: "[X:교환,W:판금,C:부식,A:흠집,U:요철,T:손상]" },
  IMS_122114: { label: "필러패널(우전)", desc: "[X:교환,W:판금,C:부식,A:흠집,U:요철,T:손상]" },
  IMS_122214: { label: "필러패널(우중)", desc: "[X:교환,W:판금,C:부식,A:흠집,U:요철,T:손상]" },
  IMS_122303: { label: "도어(우후)", desc: "[X:교환,W:판금,C:부식,A:흠집,U:요철,T:손상]" },
  IMS_122312: { label: "사이드멤버(우후)", desc: "[X:교환,W:판금,C:부식,A:흠집,U:요철,T:손상]" },
  IMS_122313: { label: "휠하우스(우후)", desc: "[X:교환,W:판금,C:부식,A:흠집,U:요철,T:손상]" },
  IMS_122314: { label: "필러패널(우후)", desc: "[X:교환,W:판금,C:부식,A:흠집,U:요철,T:손상]" },
  HST_12A001: { label: "사고이력", desc: "[1:없음,2:있음]" },
  HST_12A002: { label: "단순수리", desc: "[1:없음,2:있음]" },
  HST_140101: { label: "외판부위1랭크-1후드", desc: "[2:checked]" },
  HST_140102: { label: "외판부위1랭크-2프론트휀더", desc: "[2:checked]" },
  HST_140103: { label: "외판부위1랭크-3도어", desc: "[2:checked]" },
  HST_140104: { label: "외판부위1랭크-4트렁크리드", desc: "[2:checked]" },
  HST_140105: { label: "외판부위1랭크-5라디에이터서포트", desc: "[2:checked]" },
  HST_140201: { label: "외판부위2랭크-6쿼터패널", desc: "[2:checked]" },
  HST_140202: { label: "외판부위2랭크-7루프패널", desc: "[2:checked]" },
  HST_140203: { label: "외판부위2랭크-8사이드실패널", desc: "[2:checked]" },
  HST_140301: { label: "주요골격A랭크-9프론트패널", desc: "[2:checked]" },
  HST_140302: { label: "주요골격A랭크-10크로스멤버", desc: "[2:checked]" },
  HST_140303: { label: "주요골격A랭크-11인사이드패널", desc: "[2:checked]" },
  HST_140304: { label: "주요골격A랭크-17트렁크플로어", desc: "[2:checked]" },
  HST_140305: { label: "주요골격A랭크-18리어패널", desc: "[2:checked]" },
  HST_140401: { label: "주요골격B랭크-12사이드멤버", desc: "[2:checked]" },
  HST_140402: { label: "주요골격B랭크-13휠하우스", desc: "[2:checked]" },
  HST_140403: { label: "주요골격B랭크-14필러패널", desc: "[2:checked]" },
  HST_140404: { label: "주요골격B랭크-14필러패널A", desc: "[2:checked]" },
  HST_140405: { label: "주요골격B랭크-14필러패널B", desc: "[2:checked]" },
  HST_140406: { label: "주요골격B랭크-14필러패널C", desc: "[2:checked]" },
  HST_140407: { label: "주요골격B랭크-19패키지트레이", desc: "[2:checked]" },
  HST_140501: { label: "주요골격C랭크-15대쉬패널", desc: "[2:checked]" },
  HST_140502: { label: "주요골격C랭크-16플로어패널", desc: "[2:checked]" },
  DTS_151101: { label: "자기진단-원동기", desc: "[1:양호,2:불량]" },
  DTS_151201: { label: "자기진단-변속기", desc: "[1:양호,2:불량]" },
  DTS_152101: { label: "원동기-작동상태(공회전)", desc: "[1:양호,2:불량]" },
  DTS_152201: { label: "원동기-오일누유-로커암커버", desc: "[1:없음,2:미세누유,3:누유]" },
  DTS_152202: { label: "원동기-오일누유-실린더헤드/가스켓", desc: "[1:없음,2:미세누유,3:누유]" },
  DTS_152203: { label: "원동기-오일누유-오일팬", desc: "[1:없음,2:미세누유,3:누유]" },
  DTS_152301: { label: "원동기-오일유량", desc: "[1:적정,2:부족]" },
  DTS_152401: { label: "원동기-냉각수누수-실린더헤드/가스켓", desc: "[1:없음,2:미세누수,3:누수]" },
  DTS_152402: { label: "원동기-냉각수누수-워터펌프", desc: "[1:없음,2:미세누수,3:누수]" },
  DTS_152403: { label: "원동기-냉각수누수-라디에이터", desc: "[1:없음,2:미세누수,3:누수]" },
  DTS_152404: { label: "원동기-냉각수누수-냉각수수량", desc: "[1:적정,2:부족]" },
  DTS_152501: { label: "원동기-고압펌프(커먼레일)-디젤엔진", desc: "[1:양호,2:불량]" },
  DTS_153101: { label: "변속기-자동변속기-오일누유", desc: "[1:없음,2:미세누유,3:누유]" },
  DTS_153102: { label: "변속기-자동변속기-오일유량및상태", desc: "[1:적정,2:부족,3:과다]" },
  DTS_153103: { label: "변속기-자동변속기-작동상태(공회전)", desc: "[1:양호,2:불량]" },
  DTS_153201: { label: "변속기-수동변속기-오일누유", desc: "[1:없음,2:미세누유,3:누유]" },
  DTS_153202: { label: "변속기-수동변속기-기어변속장치", desc: "[1:양호,2:불량]" },
  DTS_153203: { label: "변속기-수동변속기-오일유량및상태", desc: "[1:적정,2:부족,3:과다]" },
  DTS_153204: { label: "변속기-수동변속기-작동상태(공회전)", desc: "[1:양호,2:불량]" },
  DTS_154101: { label: "동력전달-클러치어셈블리", desc: "[1:양호,2:불량]" },
  DTS_154201: { label: "동력전달-등속죠인트", desc: "[1:양호,2:불량]" },
  DTS_154301: { label: "동력전달-추진축및베어링", desc: "[1:양호,2:불량]" },
  DTS_154401: { label: "동력전달-디퍼렌셜기어", desc: "[1:양호,2:불량]" },
  DTS_155101: { label: "조향-동력조향작동오일누유", desc: "[1:없음,2:미세누유,3:누유]" },
  DTS_155201: { label: "조향-작동상태-스티어링기어", desc: "[1:양호,2:불량]" },
  DTS_155202: { label: "조향-작동상태-스티어링펌프", desc: "[1:양호,2:불량]" },
  DTS_155203: { label: "조향-작동상태-타이로드엔드및볼죠인트", desc: "[1:양호,2:불량]" },
  DTS_155204: { label: "조향-작동상태-스티어링죠인트", desc: "[1:양호,2:불량]" },
  DTS_155205: { label: "조향-작동상태-파워고압호스", desc: "[1:양호,2:불량]" },
  DTS_156101: { label: "제동-브레이크마스터실린더오일누유", desc: "[1:없음,2:미세누유,3:누유]" },
  DTS_156201: { label: "제동-브레이크오일누유", desc: "[1:없음,2:미세누유,3:누유]" },
  DTS_156301: { label: "제동-배력장치상태", desc: "[1:양호,2:불량]" },
  DTS_157101: { label: "전기-발전기출력", desc: "[1:양호,2:불량]" },
  DTS_157201: { label: "전기-시동모터", desc: "[1:양호,2:불량]" },
  DTS_157301: { label: "전기-와이퍼모터기능", desc: "[1:양호,2:불량]" },
  DTS_157401: { label: "전기-실내송풍모터", desc: "[1:양호,2:불량]" },
  DTS_157501: { label: "전기-라디에이터팬모터", desc: "[1:양호,2:불량]" },
  DTS_157601: { label: "전기-윈도우모터", desc: "[1:양호,2:불량]" },
  DTS_158101: { label: "연료-연료누출(LP가스포함)", desc: "[1:없음,2:있음]" },
  DTS_159101: { label: "고전원전기장치-충전구절연상태", desc: "[1:양호,2:불량]" },
  DTS_159201: { label: "고전원전기장치-구동축전지격리상태", desc: "[1:양호,2:불량]" },
  DTS_159301: { label: "고전원전기장치-고전원전기배선상태", desc: "[1:양호,2:불량]" },
};

export const BASE_STYLES = {
  THEME_COLOR: "#00aed9",
  THEME_SUB_COLOR: "#feb948",
  THEME_ITEM_COLOR: "#f0f0f0",
  THEME_BG_COLOR: "#696969",
  FONT_COLOR: "rgb(27, 28, 29)",
  BORDER_COLOR: "rgba(34,36,38,.1)",
  FONT_SIZE: {
    HEADER: {
      MAIN: 36,
      SUB: 26,
    },
    MAX: 22,
    BASE: 18,
    SUB: 14,
    MIN: 12,
  },
};
